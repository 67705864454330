"use client";

import { useEffect } from "react";
import Link from "next/link";

import * as Sentry from "@sentry/nextjs";

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div className="text-center">
      <h1 className="mb-8 text-6xl font-bold">Error</h1>
      <h2 className="mb-10 text-3xl font-bold">Oops... Something went wrong</h2>
      <p className="mb-6 text-base">
        The page you are trying to open does not work. Something might be
        broken. If you think this is an unknown error contact team-development.
      </p>

      <Link href="/" className="text-accent-blue hover:text-black">
        Back to home
      </Link>
    </div>
  );
}
